import React, {Component} from 'react';

import {Image, Layer, Line, Stage, Text, Rect} from 'react-konva';
import useImage from 'use-image';
import {Badge, Modal} from "react-bootstrap";

const IMAGE_URL = '/doll.png';
const ORGANS = '/organs.svg';

const CANVAS_WIDTH = (420);
const CANVAS_HEIGHT = (450);

const IMAGE_WIDTH = (300/2);
const IMAGE_HEIGHT = (791/2);

const BODY_TOP_POS = 30;
const BODY_MIDDLE_POS = (IMAGE_HEIGHT/2) - 50;
const BODY_BOTTOM_POS = (IMAGE_HEIGHT/2) + 110;
const BODY_TOP_MAIN_POS = 0;
const BODY_BOTTOM_MAIN_POS = IMAGE_HEIGHT - 10;

const BODY_TOP_ID = 1;
const BODY_MIDDLE_ID = 2;
const BODY_BOTTOM_ID = 3;
const BODY_TOP_MAIN_ID = 4;
const BODY_BOTTOM_MAIN_ID = 5;

const Body = () => {

    const [image] = useImage(IMAGE_URL, 'body');
    const imageRef = React.useRef();

    // when image is loaded we need to cache the image
    React.useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.getLayer().batchDraw();
        }
    }, [image]);

    return (
        <Image
            ref={imageRef}
            x={0}
            y={0}
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            image={image}
        />
    );
};

const Organs = (props) => {

    const [img] = useImage(ORGANS, 'organs_mobile');
    const imageRef = React.useRef();

    // when image is loaded we need to cache the image
    // @todo: debug image cache, it does not load on runtime
    // React.useEffect(() => {
    //     if (img && typeof img !== 'undefined') {
    //         imageRef.current.cache();
    //         imageRef.current.getLayer().batchDraw();
    //     }
    // }, [img]);

    return (
        <Image
            x={0}
            y={0}
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            image={img}
        />
    );
};

class DollBodyMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            symptom: {},
            currentHover: ""
        };
    }

    onMouseOver = (obj, item) =>{

        if(obj && item) {
            this.setState({
                currentHover: item.name
            });
        }

        document.body.style.cursor = "pointer";
    };

    onMouseOut = (obj, item) =>{

        if(obj && item) {
            this.setState({
                currentHover: ""
            });
        }

        document.body.style.cursor = "default";
    };

    renderBodyTopSymptoms(items, max) {

        const {onClick} = this.props;

        return items.slice(0, max).map((item, i)=> {

            return  <React.Fragment key={i}>
                <Text
                    x={ IMAGE_WIDTH }
                    y={ BODY_TOP_POS + (25*i) + (i*5) - 10 }
                    text={item.name_visual}
                    textDecoration={(this.state.currentHover === item.name_visual) ? "underline" : ""}
                    fontSize={18}
                    width={200}
                    fontFamily={'\'Source Sans Pro\', sans-serif'}
                    onClick={()=>{onClick(item)}}
                    onTap={()=>{onClick(item)}}
                    onMouseOver={(obj)=>{this.onMouseOver(obj,item)}}
                    onMouseOut={(obj)=>{this.onMouseOut(obj,item)}}
                    align={'left'}/>
                <Line
                    points={[IMAGE_WIDTH/2 + (27), BODY_TOP_POS + (7 * i), 140,  BODY_TOP_POS + (27 * i), 140, BODY_TOP_POS + (27 * i)]}
                    lineJoin={'round'}
                    lineCap={'round'}
                    stroke="orange"
                    strokeWidth={2}
                />
            </React.Fragment>;
        });

    }
    renderBodyMiddleSymptoms(items, max) {

        const {onClick} = this.props;

        return items.slice(0, max).map((item, i)=> {
            return  <React.Fragment key={i}>
                <Text
                    x={IMAGE_WIDTH + 20}
                    y={ BODY_MIDDLE_POS + (25*i) + (i*5) - 5 }
                    text={item.name_visual}
                    textDecoration={(this.state.currentHover == item.name_visual) ? "underline" : ""}
                    fontSize={18}
                    width={160}
                    height={30}
                    fontFamily={'\'Source Sans Pro\', sans-serif'}
                    onClick={()=>{onClick(item)}}
                    onTap={()=>{onClick(item)}}
                    onMouseOver={(obj)=>{this.onMouseOver(obj,item)}}
                    onMouseOut={(obj)=>{this.onMouseOut(obj,item)}}
                />
                <Line
                    points={[IMAGE_WIDTH/2+ (27), BODY_MIDDLE_POS + (15 * i), 160, BODY_MIDDLE_POS + (27 * i)]}
                    lineJoin={'round'}
                    lineCap={'round'}
                    stroke="orange"
                    strokeWidth={2}
                />
            </React.Fragment>;
        });
    }
    renderBodyBottomSymptoms(items, max, pos, drawline) {

        const {onClick} = this.props;

        return items.slice(0,max).map((item, i)=> {
            return  <React.Fragment key={i}>
                { drawline &&
                <Line
                    points={[IMAGE_WIDTH/2+ (27), pos + (14 * i), 160, pos + (27 * i)]}
                    lineJoin={'round'}
                    lineCap={'round'}
                    stroke="orange"
                    strokeWidth={2}
                /> }
                <Text x={IMAGE_WIDTH + 20}
                      y={ pos + (25*i) + (i*5) - 5 }
                      width={160}
                      text={item.name_visual} fontSize={18}
                      textDecoration={(this.state.currentHover === item.name_visual) ? "underline" : ""}
                      fontFamily={'\'Source Sans Pro\', sans-serif'}
                      onClick={()=>{onClick(item)}}
                      onTap={()=>{onClick(item)}}
                      onMouseOver={(obj)=>{this.onMouseOver(obj,item)}}
                      onMouseOut={(obj)=>{this.onMouseOut(obj,item)}}
                />
            </React.Fragment>;
        });
    }
    render() {

        const {symptoms} = this.props;
        const {symptom, showModal} = this.state;

        let body_top = symptoms && symptoms.length >= 1 && symptoms.filter(item => item.body_id === BODY_TOP_ID);
        let body_middle = symptoms && symptoms.length >= 1 && symptoms.filter(item => item.body_id === BODY_MIDDLE_ID);
        let body_bottom = symptoms && symptoms.length >= 1 && symptoms.filter(item => item.body_id === BODY_BOTTOM_ID);

        let body_main = symptoms && symptoms.length >= 1 && symptoms.filter(item => item.body_id === BODY_BOTTOM_MAIN_ID);
        let body_main_top = symptoms && symptoms.length >= 1 && symptoms.filter(item => item.body_id === BODY_TOP_MAIN_ID);

        return (
            <React.Fragment>
                <Modal
                    centered
                    show={showModal}
                    onHide={()=>{
                        this.setState({showModal:false});
                    }}
                    dialogClassName="modal-90w"
                    aria-labelledby="symptom-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="symptom-modal">
                          {symptom.name} <Badge variant="light">{symptom.name_visual}</Badge>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{symptom.synonymous}</p>
                    </Modal.Body>
                </Modal>
                <Stage width={CANVAS_WIDTH} height={CANVAS_HEIGHT} className={'visual-doll is-mobile'}>
                    <Layer x={(CANVAS_WIDTH/2)-200} y={20}>
                        <Body />
                        <Organs />
                    </Layer>
                    {symptoms && <Layer x={(CANVAS_WIDTH/2)-220} y={10}>
                        { body_main_top.length >= 1 && this.renderBodyBottomSymptoms(body_main_top, 6, BODY_TOP_MAIN_POS, false) }
                        { body_top.length >= 1 && this.renderBodyTopSymptoms(body_top, 6) }
                        { body_middle.length >= 1 && this.renderBodyMiddleSymptoms(body_middle, 6)}
                        { body_bottom.length > 0 && this.renderBodyBottomSymptoms(body_bottom, 6, BODY_BOTTOM_POS, true) }
                        { body_main.length >= 1 && this.renderBodyBottomSymptoms(body_main, 5, BODY_BOTTOM_MAIN_POS, false) }
                    </Layer> }
                </Stage>
            </React.Fragment>
        )
    }
}
export default DollBodyMobile;
