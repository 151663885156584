import React, {Component} from 'react';

import {Image, Layer, Line, Stage, Text} from 'react-konva';
import useImage from 'use-image';
import {TweenLite} from "gsap/TweenLite";
import Col from "react-bootstrap/Col";
import DollBodyMobile from "../DollBodyMobile";
import {Spinner} from "react-bootstrap";

const IMAGE_URL = '/doll.png';
const ORGANS = '/organs.svg';
const GEN_ICON = '/gen-icon.svg';
const BLOOD_ICON = '/blood-icon.svg';


const CANVAS_WIDTH = (810);
const CANVAS_HEIGHT = (781);

const IMAGE_WIDTH = (265);
const IMAGE_HEIGHT = (751);

const ORGANS_WIDTH = 273.7;
const ORGANS_HEIGHT = 701.6;

const BODY_TOP_POS = 0;
const BODY_MIDDLE_POS = 200;
const BODY_BOTTOM_POS = IMAGE_HEIGHT - 400;
const BODY_TOP_MAIN_POS = 10;
const BODY_BOTTOM_MAIN_POS = IMAGE_HEIGHT - 200;

const BODY_TOP_ID = 1;
const BODY_MIDDLE_ID = 2;
const BODY_BOTTOM_ID = 3;
const BODY_TOP_MAIN_ID = 4;
const BODY_BOTTOM_MAIN_ID = 5;

const BODY_POST_X = 160;
const BODY_POST_Y = 0;

let SCALE_FACTOR = 0.8;

const Body = (props) => {

    const [image] = useImage(IMAGE_URL, 'body');
    const imageRef = React.useRef();

    // // when image is loaded we need to cache the image
    React.useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.getLayer().batchDraw();
        }
    }, [image]);

    return (
        <Image
            scaleX={(props.isMobile) ? .8 : 1}
            scaleY={(props.isMobile) ? .8 : 1}
            ref={imageRef}
            x={props.x}
            y={props.y}
            width={props.width}
            height={props.height}
            image={image}
        />
    );
};

const Organs = (props) => {

    const [image] = useImage(ORGANS, 'organs');
    const imageRef = React.useRef();

    // when image is loaded we need to cache the image
    React.useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.getLayer().batchDraw();
        }
    }, [image]);

    return (
        <Image
            scaleX={(props.isMobile) ? .8 : 1}
            scaleY={(props.isMobile) ? .8 : 1}
            ref={imageRef}
            x={props.x}
            y={props.y}
            width={props.width}
            height={props.height}
            image={image}
        />
    );
};


const Icon = (props) => {

    const [image] = useImage(props.type, props.name);
    const imageRef = React.useRef();

    // when image is loaded we need to cache the image
    React.useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.getLayer().batchDraw();
        }
    }, [image]);

    return (
        <Image
            ref={imageRef}
            x={props.x}
            y={props.y}
            width={props.width}
            height={props.height}
            image={image}
        />
    );
};

class DollBodySearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentHover: "",
            isMobile:false
        };

        this.stage = null;

    }

    componentDidMount() {
        TweenLite.set(this.stage, {opacity:0});
        TweenLite.to(this.stage, 0.5, {opacity :1 });
    }

    shouldComponentUpdate(nextProps, nextState){

        const {callback} = this.props;

        if(nextProps.show){
            TweenLite.set(this.stage, {display:'block'});
            TweenLite.to(this.stage, 0.35, {x: 0, opacity : 1 });
        } else {
            TweenLite.to(this.stage, 0.35, {x: -50, opacity : 0 , onComplete:()=> {
                    TweenLite.set(this.stage, {display:'none'});
                    callback();
                    return true;
                }
            });
        }
        return true;

    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.throttledHandleWindowResize);
        }
    }

    onMouseOver = (obj, item, textVal) =>{
        this.setState({
            currentHover: item.name_visual
        });
        document.body.style.cursor = "pointer";
    };

    onMouseOut = (obj,item) =>{
        if(obj) {
            this.setState({
                currentHover: ""
            });
        }
        document.body.style.cursor = "default";
    };

    /**
     * get position based on Id
     * @param part
     * @param scale
     * @returns {number}
     */
    getBodyPosition(part, scale) {

        let bodyPosition = 0;

        switch(part) {

            case BODY_TOP_ID:
                return bodyPosition = BODY_TOP_POS * scale;

            case BODY_MIDDLE_ID:
                return bodyPosition = BODY_MIDDLE_POS * scale;

            case BODY_BOTTOM_ID:
                return bodyPosition = BODY_BOTTOM_POS * scale;

            case BODY_BOTTOM_MAIN_ID:
                return bodyPosition = BODY_BOTTOM_MAIN_POS ;

            case BODY_TOP_MAIN_ID:
                return bodyPosition = BODY_TOP_MAIN_POS;
        }

        return bodyPosition;

    }

    /**
     * render symptoms, draw lines and labels
     * @param items
     * @param showLines
     * @param isMobile
     * @returns {Uint16Array}
     */
    renderBodySymptoms(items, showLines = true, isMobile) {

        const {onClick} = this.props;
        const {currentHover} = this.state;

        SCALE_FACTOR = (isMobile) ? .8 : 1;

        return items.sort((a, b) => parseFloat(a.body_order) - parseFloat(b.body_order)).map((item, i)=> {

            // get body position based on body id from backend
            const BODY_POS = this.getBodyPosition(item.body_id, SCALE_FACTOR);

            // label position of category
            let labelPosition = (item.body_position === 'left' && !isMobile) ?
                { width:220, x: -230, y: BODY_POS + (30 * i)} :
                { width: 'auto', x: IMAGE_WIDTH + 20, y: BODY_POS + (35 * i)};

            // text of category
            let labelText = (item.body_order !== 0) ? item.name_visual + ' ('+item.name+')': item.name_visual;

            // label position
            let labelPositionText = isMobile ? (BODY_POS + (30 * i) + (item.body_position_y * SCALE_FACTOR)) : labelPosition.y;

            // points for drawing line desktop view
            let points = (item.body_position === 'left' && !isMobile) ?
                [(((IMAGE_WIDTH/2)* SCALE_FACTOR) - (item.body_position_x * SCALE_FACTOR)) - 20, (BODY_POS + (20 * i) + item.body_position_y * SCALE_FACTOR), (IMAGE_WIDTH/2) - 100,  BODY_POS + (20 * i) + (item.body_position_y * SCALE_FACTOR), 0, BODY_POS + (30 * i) + 10] :
                [(((IMAGE_WIDTH/2)* SCALE_FACTOR) + (item.body_position_x * SCALE_FACTOR)), (BODY_POS + (20 * i) + item.body_position_y * SCALE_FACTOR), 200,  BODY_POS + (20 * i) + (item.body_position_y * SCALE_FACTOR), (IMAGE_WIDTH + 10), BODY_POS + (30 * i)  + 10];

            // points for drawing line on mobile view
            let pointsLine = [
                (IMAGE_WIDTH - 50) * SCALE_FACTOR,
                (BODY_POS + (30 * i) + item.body_position_y * SCALE_FACTOR),
                (IMAGE_WIDTH/2* SCALE_FACTOR) + (item.body_position_x * SCALE_FACTOR),
                (BODY_POS + (20 * i) + item.body_position_y * SCALE_FACTOR) ];

            // draw line and label
            return  <React.Fragment key={i}>
                {item.body_id !== 0 && showLines && <Line
                    points={isMobile ? pointsLine : points}
                    lineJoin={'round'}
                    lineCap={'round'}
                    stroke="orange"
                    strokeWidth={2}
                /> }

                <Text
                    x={(showLines) ? (isMobile) ? labelPosition.x - 100 : labelPosition.x : labelPosition.x + item.body_position_x}
                    width={labelPosition.width}
                    y={ (showLines) ? (isMobile) ? labelPositionText - 5 : labelPositionText  : (labelPosition.y + item.body_position_y) }
                    text={labelText}
                    textDecoration={(currentHover === item.name_visual) ? "underline" : ""}
                    fontSize={16}
                    fontFamily={'\'Source Sans Pro\', sans-serif'}
                    onClick={()=>{onClick(item)}}
                    onTap={()=>{onClick(item)}}
                    onMouseOver={(obj)=>this.onMouseOver(obj, item, labelText)}
                    onMouseOut={(obj)=>this.onMouseOut(obj, item, labelText)}
                    align={(item.body_position === 'left' && !isMobile) ? 'right' : 'left'}
                />
            </React.Fragment>;
        });

    }

    renderBody(categories) {

        let {isMobile} = this.props;

        return (
            <Layer x={(isMobile) ? 0 : BODY_POST_X} y={0} ref={(div)=>this.node = div} >
                <Body x={0} y={0} width={IMAGE_WIDTH} height={IMAGE_HEIGHT} isMobile={isMobile} scaleX={SCALE_FACTOR} scaleY={SCALE_FACTOR} />
                <Organs x={-5} y={0} width={ORGANS_WIDTH} height={ORGANS_HEIGHT} isMobile={isMobile} scaleX={SCALE_FACTOR} scaleY={SCALE_FACTOR} />
                { categories && <Icon type={BLOOD_ICON} name={'blood'}  x={IMAGE_WIDTH/2 + 110} y={CANVAS_HEIGHT/2 + 200} width={65.4/2} height={49.3/2} /> }
                { categories &&<Icon type={GEN_ICON} name={'gen'} x={IMAGE_WIDTH/2 + 110} y={CANVAS_HEIGHT/2 + 150} width={89.8/2} height={93.2/2} /> }
            </Layer>
        );

    }

    renderCategories(categories) {

        let {isMobile} = this.props;


        let body_top = categories && categories.filter(item => item.body_id === BODY_TOP_ID);
        let body_middle = categories && categories.filter(item => item.body_id === BODY_MIDDLE_ID);
        let body_bottom = categories && categories.filter(item => item.body_id === BODY_BOTTOM_ID);
        let main_top = categories && categories.filter(item => item.body_id === BODY_TOP_MAIN_ID);
        let main_bottom = categories && categories.filter(item => item.body_id === BODY_BOTTOM_MAIN_ID);

        return(
            <Layer x={(isMobile) ? 0 : BODY_POST_X}>
                {  this.renderBodySymptoms(main_top, false, isMobile) }
                {  this.renderBodySymptoms(body_top, true, isMobile) }
                {  this.renderBodySymptoms(body_middle, true, isMobile) }
                {  this.renderBodySymptoms(body_bottom, true, isMobile) }
                {  this.renderBodySymptoms(main_bottom, false, isMobile) }
            </Layer>
        );

    }

    render() {

        const {categories, isMobile, onClick, loading} = this.props;

        return (
                <Col sm={12} lg={9} ref={(div)=>this.stage = div}>
                    {loading && <Spinner size="sm" animation="border" style={{position:'absolute', left:"200px", top:"20px"}} /> }
                    <div className={loading ? "blur" : ""}>
                        { isMobile ?  <DollBodyMobile symptoms={categories} onClick={onClick}/> :

                        <Stage width={CANVAS_WIDTH} height={CANVAS_HEIGHT} className={'visual-doll'}
                               // onTap={(e)=>{}}
                               onContentTouchMove={(e)=>{ e.preventDefault(); }}
                        >
                           { this.renderBody(categories) }
                           { categories && this.renderCategories(categories) }
                        </Stage> }
                    </div>

                </Col>
            )
    }
}
export default DollBodySearch;
