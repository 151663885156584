import React, {Component} from 'react';
import BaseLayout from '../components/Layout/BaseLayout';
import {graphql} from 'gatsby';
import {db} from "../graphql/client";
import {gql} from 'apollo-boost';

import DollBodySearch from "../components/DollBodySearch";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import brain from "../assets/images/body/brain.svg";
import heart from "../assets/images/body/heart.svg";
import blood from "../assets/images/icons/blood-icon.svg";
import gen from "../assets/images/icons/gen-icon.svg";
import stomach from "../assets/images/body/stomach.svg";
import bone from "../assets/images/body/bone.svg";

import {DISEASES_URL} from "../constants";
import ResponsiveContainer from "../components/ResponsiveContainer";

// IE polyfill for canvas
// require("core-js/modules/es7.string.trim-right");
// require("core-js/modules/es7.string.trim-left");

class VisualSearch extends Component {

    constructor(props){

        super(props);
        this.state = {
            show:false,
            categories:null,
            selectedCategory:null,
            allSymptoms:null,
            symptoms:null,
            showBody:true,
            loading:false
        };
        this.backToOverview = this.backToOverview.bind(this);
        this.imageHolder = null;

    }
    componentDidMount(){

        this.setState({
            show:true,
            loading:true
        });

        const {showBody} = this.state;

        if(typeof window !== "undefined") {
            window.addEventListener('popstate', (e) => {

            });
        }

        // query symptoms
        db.query({
            query: gql`
            {
                allSymptoms {
                    name
                    category
                    synonymous
                    diseases {
                        name
                        uri
                    }
                }
                categories {
                    body_id
                    body_order
                    body_position_x
                    body_position_y
                    body_position
                    name_visual
                    name
                    id
                }            
            }` })
            .then((response) => {
                this.setState({
                    categories: response.data.categories,
                    allSymptoms: response.data.allSymptoms,
                    loading:false
                });
            })
            .catch(error => {
                this.setState({
                    error: true,
                    loading:false,
                    errorMessage: "Can't fetch data [symptoms]"
                });
            });



    }

    backToOverview() {
        this.setState({
            selectedCategory:null,
            symptoms:null,
            showBody:true
        });
    }

    render() {

        const {data, location, isMobile} = this.props;
        const {categories, selectedCategory, symptoms, allSymptoms, showBody, loading} = this.state;
        return (
            <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title}  showSubNav={true}>
                <section className="visual-search">
                    <h1>{data.gravql.page.title}</h1>
                    <Row>
                        <Col sm={12} lg={3}>
                            <ul className='visual-search__breadcrumbs'>
                                <li className={(selectedCategory===null) ? 'active' : ''}><a href="#" onClick={this.backToOverview}>Overzicht</a></li>
                                {selectedCategory && (selectedCategory.id !== 0) ? <li><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 50 50"><path fill="#444" d="M15.563 40.836a.997.997 0 0 0 1.414 0l15-15a1 1 0 0 0 0-1.414l-15-15a1 1 0 0 0-1.414 1.414L29.856 25.13 15.563 39.42a1 1 0 0 0 0 1.414z"/></svg></li> : ''}
                                {selectedCategory && (selectedCategory.id !== 0) ? <li className={'active'}> { selectedCategory.name_visual } </li> : ''}
                            </ul>
                            <div ref={(ref)=>{this.imageHolder = ref;}}>
                                {/*{ selectedCategory && this.renderImage(selectedCategory.id) }*/}
                                { selectedCategory && selectedCategory.id == 3  &&  <img src={brain} width={150} height={150} alt="brain" /> }
                                { selectedCategory && selectedCategory.id == 10  && <img src={brain} width={150} height={150} alt="brain" /> }
                                { selectedCategory && selectedCategory.id == 2  && <img src={heart} width={200} height={200} alt="heart" /> }
                                { selectedCategory && selectedCategory.id == 6  && <img src={stomach} width={200} height={200} alt="stomach" /> }
                                { selectedCategory && selectedCategory.id == 14  && <img src={stomach} width={200} height={200} alt="stomach" /> }
                                { selectedCategory && selectedCategory.id == 7  && <img src={blood} width={80} height={80} alt="blood" /> }
                                { selectedCategory && selectedCategory.id == 5  && <img src={gen} width={80} height={80} alt="gen" /> }
                                { selectedCategory && selectedCategory.id == 9  && <img src={bone} width={200} height={200} alt="bone" /> }
                            </div>
                        </Col>

                        <ResponsiveContainer>
                           <DollBodySearch show={showBody} categories={categories} loading={loading}
                                  callback={()=>{
                                      const {selectedCategory} = this.state;

                                      if(selectedCategory.id !== null) {
                                          this.setState({
                                              symptoms: allSymptoms.filter((symptom) => selectedCategory.id === symptom.category ).sort((a,b) => a.name.localeCompare(b.firstname))
                                          });
                                      }

                                  }}
                                  onClick={(item)=>{
                                      this.setState({
                                          selectedCategory:item,
                                          showBody:false
                                      });
                                  }}/>
                        </ResponsiveContainer>
                        {symptoms && selectedCategory &&  <Col sm={12} lg={9} className={'symptoms-overview'}>
                            <div className={`symptoms-overview-wrapper ${(symptoms && selectedCategory) ? 'is-selected' : ''}`}>
                            <div className="symptom border-0">
                                <div className="row">
                                    <div className="double-column">
                                       <strong>Symptoom</strong>
                                    </div>
                                    <div className="column">
                                        <strong>Stapelingsziekten</strong>
                                    </div>
                                </div>
                            </div>
                            {symptoms && symptoms.map((symptom, key)=>{
                                return(
                                        <div className="symptom" key={key}>
                                            <div className="symptom-inner">
                                            <div className="row">
                                                <div className="double-column">
                                                    <div className="symptom-content">
                                                        <span name={symptom.name} />
                                                        <h5>{symptom.name}</h5>
                                                        <br/>
                                                        <p>{symptom.synonymous}</p>
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <ul className="symptom-disease__list">
                                                    {symptom.diseases && symptom.diseases.map((s, key)=>{
                                                        return(
                                                            <li key={key}><a href={DISEASES_URL + s.uri}>{s.name}</a></li>
                                                        );
                                                    })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            </div>
                        </Col>}
                    </Row>
                </section>
            </BaseLayout>
        )
    }
}

export default VisualSearch;

export const query = graphql`
  query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
              breadcrumbs
            header {
                title
                body_classes
            }
            children {
                title
                route
                  breadcrumbs
                header {
                    title
                    body_classes
                }
                 children {
                    title
                    route
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            breadcrumbs
            header {
                body_classes
            }
            children {
                title
                route  
                id
                content  
                template 
                header {
                    body_classes
                    title
                }
                 children {
                    title
                    route
                    content
                }         
            }
          
            metadata {
                name
                content
            }   
        }
    }
  }
`;
